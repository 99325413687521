import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { InputHelper } from "@services/input-helper";
import { BaseComponent } from "@abstract/BaseComponent";
import { ModelOrderDetail } from "@app/interfaces/order";
import { makeOrderRevenueSummary } from "@wearewarp/js-const-finance";
import { Log } from '@services/log';

@Component({
  selector: 'shipment-entry-revenue-summary',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class ShipmentEntryRevenueSummary extends BaseComponent {
  public displayInfo: any = {};
  public children: any[] = []
  private _model: ModelOrderDetail;
  @Input() get model(): ModelOrderDetail {
    return this._model;
  }
  set model(value: ModelOrderDetail) {
    this._model = value;
    this.buildDisplayInfo();
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    const shipmentId = this.model?.shipmentIds?.[0];
    if (!shipmentId) return;
    this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${shipmentId}/childrens`).subscribe((response) => {
      this.children = response.data.list_data;
      this.buildDisplayInfo();
    });
  }

  private buildDisplayInfo() {
    // set Currency = USD cho tổng quát vì có thể các leg có thể khác nhau Canada FX Rate, 
    const handleSettingNotMatch = this.model?.clientIds?.includes('01HRZEJV34B82ZVT2B1TE06VWX'); // gopuff
    const dataSummary = makeOrderRevenueSummary(this.model, this.model?.metadata?.shipments, this.children, { handleSettingNotMatch, includeAdditionalCost: true });
    Log.d('dataSummary:', dataSummary);
    this.displayInfo.shipments = [];
    this.displayInfo.childrenShipments = [];
    let totalRevenueAllocation = 0;
    let totalCostAllocation = 0
    for (let line of dataSummary.lines) {
      if (line.type == 'order') {
        this.displayInfo.order = {
          warpId: this.showOrderCode(this.model),
          total: InputHelper.formatMoney2(`${line.value}`)
        }
      } else if (line.type == 'shipment') {
        const shipment = this.model?.metadata?.shipments?.find(shipment => shipment.id == line.id);
        const warpId = shipment ? this.showShipmentCode(shipment) : line.code;
        this.displayInfo.shipments.push({
          warpId,
          total: InputHelper.formatMoney2(`${line.value}`),
        })
      } else if (line.type == 'additional') {
        this.displayInfo.childrenShipments.push({
          warpId: "Additional invoice",
          total: InputHelper.formatMoney2(`${line.value}`)
        })
      } else {
        const leg = this.children.find(l => l.id == line.id);
        const warpId = leg ? this.showShipmentCode(leg) : line.code;
        const shipmentTransitType = leg ? (leg.shipmentTransitType === Const.ShipmentTransitType.leg ? 'LEG' : 'SUB') : '';
        this.displayInfo.childrenShipments.push({
          warpId,
          shipmentTransitType,
          total: InputHelper.formatMoney2(`${line.value}`),
          revenueAllocation: line.revenueAllocation ? InputHelper.formatMoney2(`${line.revenueAllocation}`) : '',
          costAllocation: line.costAllocation ? InputHelper.formatMoney2(`${line.costAllocation}`) : '',
        });
        totalRevenueAllocation += line.revenueAllocation || 0;
        totalCostAllocation += line.costAllocation || 0;
      }
    }

    this.displayInfo.total = InputHelper.formatMoney2(`${dataSummary.total}`);
    this.displayInfo.totalRevenueAllocation = totalRevenueAllocation ? InputHelper.formatMoney2(`${totalRevenueAllocation}`) : '';
    this.displayInfo.totalCostAllocation = totalCostAllocation ? InputHelper.formatMoney2(`${totalCostAllocation}`) : '';
  }
    
}
