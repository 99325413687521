import { BaseComponent } from '@abstract/BaseComponent';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModelOrderDetail } from '@app/interfaces/order';
import { Const } from '@const/Const';
import { Utils } from '@services/utils';

@Component({
  selector: 'shipment-entry-single-detail',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../../../../dialogs/dialogs.scss']
})
export class ShipmentEntrySingleDetail extends BaseComponent {

  @Input() model: ModelOrderDetail;
  @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();
  public isTurnOnEditMode = Const.defaultTurnOnEditMode;
  public isShowListJobs = false;

  ngOnInit(): void {
    this.buildDisplayInfo();
  }

  onEditModeChange(event) {
    this.isTurnOnEditMode = !!event;
  }

  onBtnRefreshDetailOrder() {
    this.refreshDetailOrder.emit();
  }
  
  shouldShowRevenueSummary = false;
  private buildDisplayInfo() {
    if (!this.model) return;
    if (Utils.isArrayNotEmpty(this.model.metadata?.shipments?.[0]?.legIds) || Utils.isArrayNotEmpty(this.model.metadata?.shipments?.[0]?.subIds)) {
        this.shouldShowRevenueSummary = true;
      }
  }
}